import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'sb-ui-offerletter',
  templateUrl: './offerletter.component.html',
  styleUrls: ['./offerletter.component.scss']
})
export class OfferletterComponent implements OnInit, OnChanges {

	contentTop: string = null;
	contentBottom: string = null;

	date: Date = null;
	signatureOpened = false;
	declineOpened = false;
	isAccepting = false;
	isDeclining = false;

	isSigned = false;

	@Input() content: string = null;
	@Input('signature-url') signatureUrl: string = null;
	@Output() accepted = new EventEmitter<string>();
	@Output() declined = new EventEmitter<Array<string>>();
	@Output() printClicked = new EventEmitter<null>();

	constructor() { }

	ngOnInit() {
		this.date = new Date();
	}

	ngOnChanges() {
		if (this.content !== null) {
			const parts = this.content.split('%%%DIV%%%');
			if (parts.length === 2) {
				this.contentTop = parts[0];
				this.contentBottom = parts[1];
			} else {
        this.contentTop = this.content;
      }
		}
	}

	showSignature() {
		this.signatureOpened = true;
	}

	setSignature(signatureUrl: string) {
    console.log(signatureUrl);
		this.signatureOpened = false;
		this.signatureUrl = signatureUrl;
		this.isSigned = (signatureUrl !== null);
	}

	openDecline() {
		this.declineOpened = true;
	}

	setDecline(reasons: Array<string>) {
		this.declined.emit(reasons);
	}

	accept() {
		this.accepted.emit(this.signatureUrl);
	}

	print() {
		this.printClicked.emit();
	}

	setDeclining(value: boolean = true): void {
		this.isDeclining = value;
	}

	setAccepting(value: boolean = true): void {
		this.isAccepting = value;
	}

}
