import { Component, Input, Output, EventEmitter, isDevMode } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { COUNTRY_NAME } from 'projects/sb-lib/src/lib/models/constants';
import { GoogleMapsService } from '../../../api/services/google-maps.service';

@Component({
	selector: 'sb-forms-zipcity',
	templateUrl: './zipcity.component.html',
	styleUrls: ['./zipcity.component.scss']
})
export class ZipcityComponent {

	busy = false;

	constructor(
		private maps: GoogleMapsService
	) {}

	@Input() zipControl: UntypedFormControl;
	@Input() cityControl: UntypedFormControl;
	@Input() readonly: boolean;
	@Input() validMode = 'touched';
	@Input() layout = 'compact';

	@Output() editClicked = new EventEmitter<void>();

	onZipChanged(value: string) {
		this.busy = true;
		this.maps.getLocationData(value + ', ' + COUNTRY_NAME).then(result => {
			if (result.city !== null) {
				this.cityControl.setValue(result.city);
			}
		}, (error) => {
			this.cityControl.setValue('');
		}).then(() => {
			this.busy = false;
		});
	}

}
