<ng-container *transloco="let t;">
<div class="uk-container">

	<div class="uk-section">
		<h1>{{ t('search.search_event') }}</h1>

		<div class="uk-margin-large" *ngIf="isInitialising === true">
			<p class="uk-text-center"><span uk-spinner="ratio: 3"></span></p>
			<p class="uk-text-center">
				{{ t('search.loading_search') }}
			</p>
		</div>

		<form *ngIf="searchvalues !== null" class="uk-form-stacked" [formGroup]="searchForm" (submit)="search()" novalidate>
			<div uk-grid>
				<div class="uk-width-2-3@m">
					<p class="headline">{{ t('common.location') }}</p>
					<div uk-grid class="uk-child-width-1-2@m">
						<div>
							<div class="uk-margin">
								<label class="uk-form-label">{{ t('search.postal_code') }}</label>
								<div class="uk-form-controls">
									<input class="uk-input" type="text" formControlName="zip">
								</div>
							</div>
							<div class="uk-margin">
								<label class="uk-form-label">{{ t('search.search_radius_is', { radius: distance }) }}</label>
								<div class="uk-form-controls">
									<input class="uk-range" type="range" formControlName="distance" min="10" max="50" step="10">
								</div>
							</div>
						</div>
						<div>
							<div class="map">
								<label class="uk-form-label">{{ t('search.event_map') }}</label>
                <google-map
                  width="100%"
                  height="190px"
                  [center]="center"
                  [options]="mapOptions"
                >
									<map-marker
                    *ngIf="mapdata"
                    #mainMarker="mapMarker"
                    [position]="{lat: mapdata.lat, lng: mapdata.lon}"
                    (mapClick)="openMainInfo(mainMarker, mapdata.address)"
                  >
									</map-marker>
                  <map-info-window #mainInfo><span [innerHTML]="openedInfoContent"></span></map-info-window>
                </google-map>
							</div>
						</div>
					</div>

				</div>
				<div class="uk-width-1-3@m">
					<p class="headline">{{ t('search.details') }}</p>
					<div class="uk-margin">
						<label class="uk-form-label">{{ t('search.open_positions') }}</label>
						<div class="uk-form-controls">
							<select class="uk-select" type="text" formControlName="jobgroup">
								<option *ngFor="let jobgroup of searchvalues.jobgroups" [value]="jobgroup.id">{{jobgroup.title}}</option>
							</select>
						</div>
					</div>
					<div class="uk-margin">
						<div class="uk-form-controls">
							<button type="submit" class="uk-button uk-width-1-1 kn-call-to-action">{{ t('search.search_now') }}</button>
						</div>
					</div>
				</div>
			</div>

		</form>

		<div class="uk-section uk-section-large" *ngIf="isLoading === true">
			<p class="uk-text-center"><span uk-spinner="ratio: 3"></span></p>
			<p class="uk-text-center">
				{{ t('search.search_events') }}
			</p>
		</div>

		<div class="uk-section uk-section-small event-list" *ngIf="events !== null && events.length > 0">
			<hr>
			<h2><span uk-icon="icon: calendar"></span> {{ t('search.search_result') }}</h2>

			<div uk-grid="masonry: true" class="uk-child-width-1-3@m uk-child-width-1-2@s">

				<div *ngFor="let event of events">
					<app-event [mode]="'home-card'" [event]="event"></app-event>
				</div>

			</div>
		</div>

		<div class="uk-section uk-section-small event-list" *ngIf="hasSearched === true && isLoading !== true && (events === null || events.length === 0)">
			<hr>
			<h2><span uk-icon="icon: calendar"></span> Events</h2>

			<p>{{ t('search.sorry_no_matching_events_found') }}</p>

		</div>

	</div>

</div>

</ng-container>
