import { PreregInitDataType } from './personal-data/personal-data.component';
import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PreregistrationComponent, PreregModes } from '../preregistration.component';
import { ApiService } from '../../../services/api.service';
import { DataService } from '../../../services/data.service';
import { CandidateEventModel } from 'projects/sb-lib/src/lib/models/candidateevent.model';
import { EventModel } from 'projects/sb-lib/src/lib/models/event.model';
import { CandidateModel } from 'projects/sb-lib/src/lib/models/candidate.model';

@Component({
	selector: 'app-prereg-friday',
	templateUrl: './prereg-friday.component.html',
	styleUrls: ['./prereg-friday.component.scss']
})
export class PreregFridayComponent extends PreregistrationComponent implements OnInit {

	@Input() mode: string = PreregModes.REGISTRATION;
	@Input() event: EventModel = null;
	@Input() candidateevent: CandidateEventModel = null;
  @Input() init: PreregInitDataType = null;

  @Output() collected = new EventEmitter<any>();

	registrationSuccess = false;
	registrationLoading = false;

	data: any = {
		candidate: {
			event: null,
			personal: null,
			slot: null,
			work: null,
			job: null,
			skills: null,
			languages: null,
			marketing_source: null
		},
		step: 1
	};
	errors: any = {};

	slotError = false;

	constructor(
		private api: ApiService,
		private dataService: DataService,
	  public transloco: TranslocoService
  ) {
		super();
	}

	ngOnInit() {
	}

	setSlot(slot: any) {
		this.data.candidate.slot = slot;
		this.slotError = false;
	}

	register() {
		this.slotError = false;
		this.registrationLoading = true;
		this.data.candidate.event = this.event.id;
		this.data.candidate.marketing_source = this.dataService.getMarketingSource();
    if (this.candidateevent) {
      this.data.candidate.candidateevent = this.candidateevent?.id;
    }
		this.api.registerFriday(this.data.candidate, this.mode).subscribe((result: any) => {
			this.registrationLoading = false;
			this.registrationSuccess = true;
      this.collected.emit({
        candidate: new CandidateModel().deserialize(result.candidate),
        candidateevent: new CandidateEventModel().deserialize(result.candidateevent),
      });
		}, (error: any) => {
			switch(error.fields.type) {
				case ('slot-not-available'): {
					this.data.candidate.slot = null;
					this.data.step = 5;
					break;
				}
				case ('email-exists'): {
					this.data.step = 1;
					this.errors = {
						email: {'email-exists': true}
					};
					break;
				}
				case ('phonenumber-invalid'): {
					this.data.step = 1;
					this.errors = {
						mobile: {'phonenumber-invalid': true}
					};
					break;
				}
			}
			this.registrationLoading = false;
			this.registrationSuccess = false;
		});
	}

}
