import { Injectable } from '@angular/core';
import { Validators, AbstractControlOptions } from '@angular/forms';

import { DataCoreService } from 'projects/sb-lib/src/lib/modules/api/services/data-core.service';

export const MAXYEARS = 60;
export const VALIDATOR_PATTERN_IDNUMBER = '^[0-9]+$';
export const VALIDATOR_PATTERN_NAME = '.*[A-Za-z]+.*'; // '^[A-Za-z\u00C0-\u00FF\-\' ]*$';
export const VALIDATOR_PATTERN_ADDRESS = '[A-Za-z0-9\u00C0-\u00FF\(\)\-\/\' ]*';
export const VALIDATOR_PATTERN_ZIP = '^.*$';
export const VALIDATOR_PATTERN_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALIDATOR_PATTERN_MOBILE = '^(\\+[0-9])*[0-9\(\)\-/ ]*$';
export const VALIDATOR_PATTERN_MIN_ONE_LETTER = '.*[A-Za-z]+.*';
export const VALIDATOR_STATE = '^.*$';

@Injectable({
	providedIn: 'root'
})
export class DataService extends DataCoreService {

	marketingSource: string = null;

	constructor(
	) {
		super();
		this.marketingSource = JSON.parse(sessionStorage.getItem('marketingSource'));
	}

	setMarketingSource(source: string = null) {
		this.marketingSource = source;
		this.saveState();
	}

	getMarketingSource(): string {
		return this.marketingSource;
	}

	private saveState() {
		sessionStorage.setItem('marketingSource', JSON.stringify(this.marketingSource));
	}

}
