import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { Searchvalues } from '../../types/searchvalues';
import { ApiService } from '../../services/api.service';
import { EventModel } from 'projects/sb-lib/src/lib/models/event.model';
import { AppService } from '../../services/app.service';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { GOOGLE_MAPS_OPTIONS } from '../../constants';

declare var UIkit: any;

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @ViewChild('mainMarker', { static: false }) mainMarker: MapMarker;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;

	mapdata: any;
  mapOptions: google.maps.MapOptions = GOOGLE_MAPS_OPTIONS;
  markers: Array<MapMarker> = [];
  openedInfoContent: string = null;

	searchvalues: Searchvalues = null;
	openEvent: EventModel = null;
	modalMode: string = null;
	isLoading = false;
	isInitialising = true;
	hasSearched = false;
	events: EventModel[] = null;

	distance = 50;
  center: google.maps.LatLngLiteral;

	// form data
	searchForm = new UntypedFormGroup({
		jobgroup: new UntypedFormControl(''),
		customer: new UntypedFormControl(''),
		zip: new UntypedFormControl(''),
		distance: new UntypedFormControl(this.distance),
	});

	constructor(
		private api: ApiService,
		private app: AppService,
	  public transloco: TranslocoService
  ) { }

	ngOnInit() {
		this.mapdata = this.app.getEnum('MAPDATA', false);
    this.center = {
        lat: parseFloat(this.mapdata.lat),
        lng: parseFloat(this.mapdata.lon),
    };
		this.api.getSearchvalues()
			.subscribe((searchvalues) => {
				this.searchvalues = searchvalues;
				this.isInitialising = false;
        setTimeout(() => {
          this.openMainInfo(this.mainMarker, this.mapdata.address);
        }, 100);
		});
		this.searchForm.get('distance').valueChanges.subscribe(distance => {
			this.distance = distance;
		});
  }

  public openMainInfo(marker: MapMarker, content: string) {
    this.openedInfoContent = content;
    this.infoWindow.open(marker);
  }

	public openEventDetails(event: EventModel, mode: string) {
		this.openEvent = event;
		this.modalMode = mode;
		UIkit.modal('#eventModal', {}).show();
	}

	public search() {
		this.isLoading = true;
		this.events = null;
		this.api.searchEvents(this.searchForm.value)
			.subscribe((events) => {
				this.events = events.filter((event: EventModel) => {
					return !event.is_searay;
				});
				this.isLoading = false;
				this.hasSearched = true;
		});
	}

}
