import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApiService } from '../../../services/api.service';
import { UiService } from '../../../services/ui.service';

import { CandidateEventModel } from '../../../../../../sb-lib/src/lib/models/candidateevent.model';
import { CandidateModel } from 'projects/sb-lib/src/lib/models/candidate.model';
import { forkJoin } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { EventModel } from 'projects/sb-lib/src/lib/models/event.model';
import { CollectEventdataComponent } from '../../../partials/collect-eventdata/collect-eventdata.component';

@Component({
	selector: 'app-myevents',
	templateUrl: './myevents.component.html',
	styleUrls: ['./myevents.component.scss']
})
export class MyeventsComponent implements OnInit {

	candidate: CandidateModel = null;
	candidateevents: CandidateEventModel[] = null;
	loadingMode = 'loading';

	radiusForm = new UntypedFormGroup({
		radius: new UntypedFormControl(0),
	});
	radiusMode = false;
	radiusSending = false;
	radiusBackup: number = null;

  @ViewChild('datacollector') datacollector: CollectEventdataComponent;

	constructor(
		private ui: UiService,
		private api: ApiService,
	  public transloco: TranslocoService
  ) { }

	ngOnInit() {
		this.loadEvents();
		this.radiusForm.get('radius').valueChanges.subscribe(radius => {
			this.candidate.search_radius = radius;
		});
	}

	loadEvents() {
		this.loadingMode = 'loading';
		forkJoin([
			this.api.getProfile(),
			this.api.getCandidateEvents()
		]).subscribe((result: any) => {
			this.candidate = new CandidateModel().deserialize(result[0].candidate);
			this.candidateevents = this.getSorted(result[1]);
			this.loadingMode = null;
			this.radiusForm.get('radius').setValue(this.candidate.search_radius);
      // event data collector
      if (this.candidate.hasTempEmail()) {
        if (Array.isArray(this.candidateevents) && this.candidateevents.length > 0) {
          const candidateevent = this.candidateevents.find((candidateevent: CandidateEventModel) => {
            return (candidateevent.status === 'registered' || candidateevent.status === 'ready') && candidateevent.job_id === null;
          });
          this.datacollector.showModal(new CandidateEventModel().deserialize((candidateevent) ? candidateevent : this.candidateevents[0]));
        }
      }
		},
		() => {
			this.loadingMode = 'error';
		});
	}

	unregister(candidateevent: CandidateEventModel) {
		this.ui.showConfirm(this.transloco.translate('profile.myevents.really_want_to_unregister_from_event'),
			() => {
				this.api.deleteCandidateEvent(candidateevent.event_id).subscribe(
					result => {
						this.candidateevents = result.candidateevents;
						this.ui.addMessage(result.message, 'success');
					},
					() => {},
					() => {}
				);
			},
			() => {}
		);
	}

	cancelRearchRadius() {
		this.radiusMode = false;
		this.candidate.search_radius = this.radiusBackup;
		this.radiusBackup = null;
	}

	setRadiusMode() {
		this.radiusBackup = this.candidate.search_radius;
		this.radiusMode = true;
	}

	setSearchradius() {
		this.radiusSending = true;
		this.api.setSearchRadius(this.candidate.search_radius).subscribe(() => {
			this.ui.addMessage((this.candidate.search_radius > 0) ? this.transloco.translate('profile.myevents.your_search_radius_was_set') : this.transloco.translate('profile.myevents.your_search_radius_is_turned_off'), 'success');
			this.radiusSending = false;
			this.radiusMode = false;
		});
	}

  onCollected(data: any): void {
    this.candidate = data.candidate;
    this.candidateevents = this.candidateevents.map((actual: CandidateEventModel) => {
      return (actual.id === data.candidateevent?.id) ? data.candidateevent : actual;
    });
  }

	private getSorted(candidateevents: Array<CandidateEventModel>): Array<CandidateEventModel> {
		let upcoming = candidateevents.filter((candidateevent: CandidateEventModel) => candidateevent.event.status === 'online' || candidateevent.event.status === 'running');
		let finished = candidateevents.filter((candidateevent: CandidateEventModel) => candidateevent.event.status === 'finished' || candidateevent.event.status === 'archived');
		upcoming.sort((a: CandidateEventModel, b: CandidateEventModel) => {
			return (a.event.date_start > b.event.date_start) ? 1 : -1;
		});
		finished.sort((a: CandidateEventModel, b: CandidateEventModel) => {
			return (a.event.date_start < b.event.date_start) ? 1 : -1;
		});
		return [...upcoming, ...finished];
	}


}
