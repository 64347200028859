import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { VALIDATOR_PATTERN_EMAIL } from '../../../../sb-lib.service';
import { FormhelperService } from '../../../forms/services/formhelper.service';
import * as _moment from 'moment-timezone';
import { DATETIMES } from 'projects/sb-lib/src/lib/models/constants';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

enum UserLoginModes {
  Login = 'login',
  Password = 'password',
}

@Component({
	selector: 'sb-auth-userlogin',
	templateUrl: './userlogin.component.html',
	styleUrls: ['./userlogin.component.scss']
})
export class UserloginComponent implements OnChanges {

	@Input() mode = UserLoginModes.Login;
	@Input() title: string;
	@Input() error: string = null;
	@Input() events: any[] = [];

	@Output() loginClicked = new EventEmitter<any>();
	@Output() passwordChanged = new EventEmitter<any>();

	loginForm: UntypedFormGroup = new UntypedFormGroup({
		email: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_EMAIL)]),
		password: new UntypedFormControl('', Validators.required),
		event: new UntypedFormControl(''),
	});

  UserLoginModes = UserLoginModes;

	constructor(
		private fh: FormhelperService
	) { }

	ngOnChanges() {
		this.events.forEach((event: any) => {
			event.select_value = event.name + ' ' + moment(event.date_start).format(DATETIMES.DATE_SHORT);
		});
	}

	onSubmit() {
		this.loginClicked.emit({
			email: this.loginForm.value.email,
			password: this.loginForm.value.password,
			event: this.fh.selectToValue(this.loginForm.value.event, 'id'),
		});
	}

  changeToPassword(): void {
    this.mode = UserLoginModes.Password;
  }

  onPasswordCancelled(): void {
    this.mode = UserLoginModes.Login;
  }

  onPasswordChanged(data: any): void {
    this.passwordChanged.emit(data);
  }

}
