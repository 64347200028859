<ng-container *transloco="let t">
<div uk-grid class="uk-grid-small">
	<div class="uk-width-1-4@m">
    	<sb-forms-input [control]="zipControl" [readonly]="readonly" [validMode]="validMode" [label]="t('sblib.common.zip')" (changed)="onZipChanged($event)" (editClicked)="editClicked.emit()"></sb-forms-input>
	</div>
	<div class="uk-width-expand@m">
    	<sb-forms-input [control]="cityControl" [readonly]="readonly || busy" [validMode]="validMode" [label]="t('sblib.common.city')" [busy]="busy" (editClicked)="editClicked.emit()"></sb-forms-input>
	</div>
</div>
</ng-container>
