<ng-container *transloco="let t">
  <div
    class="form-field apple-switch"
    [ngClass]="{
      error: isInvalid(),
      success: isValid(),
      busy: isBusy(),
      readonly: readonly,
      'is-desktop': isDesktop()
    }"
  >
    <div *ngIf="cbfirst !== true" uk-grid class="uk-grid-small">
      <div class="uk-width-expand">
        <label>
          {{ label }}
          <span class="checkbox-error">{{ t('sblib.forms.errors.checkbox_required') }}</span>
        </label>
      </div>
      <div class="uk-width-auto" [hidden]="hidevalue === true">
        <span *ngIf="control.value === true">{{ t('sblib.common.yes') }}</span
        ><span *ngIf="control.value !== true">{{ t('sblib.common.no') }}</span>
      </div>
      <div class="uk-width-auto">
        <input
          type="checkbox"
          [formControl]="control"
          [attr.disabled]="readonly === true ? true : null"
          (change)="onChanged()"
        />
      </div>
    </div>
    <div *ngIf="cbfirst === true" uk-grid class="uk-grid-small">
      <div class="uk-width-auto">
        <input
          type="checkbox"
          [formControl]="control"
          [attr.disabled]="readonly === true ? true : null"
          (change)="onChanged()"
        />
      </div>
      <div class="uk-width-expand">
        <label>
          {{ label }}
          <span class="checkbox-error">{{ t('sblib.forms.errors.checkbox_required') }}</span>
        </label>
      </div>
    </div>
  </div>
</ng-container>
