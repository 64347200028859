export class BaseModel {
  updateable: boolean;
  deletable: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  propertyFilled(property: any): boolean {
    return property !== null && property !== undefined && property !== '';
  }
}
