import { Component, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
	selector: 'sb-ui-signaturefield',
	templateUrl: './signaturefield.component.html',
	styleUrls: ['./signaturefield.component.scss']
})
export class SignaturefieldComponent implements AfterViewInit {

	isSigned = true;

	constructor() { }

	@Output() signed = new EventEmitter<string | null>();
	@ViewChild(SignaturePad) signaturePad: SignaturePad;
	// @ViewChild('container') container: ElementRef;^

	signaturePadOptions: any = { // passed through to szimek/signature_pad constructor
		minWidth: 2,
		maxWidth: 4,
		canvasWidth: 688,
		canvasHeight: 250
	};

	ngAfterViewInit() {
		this.signaturePad.clear();
    // this.signaturePad.set('canvasWidth', this.container.nativeElement.clientWidth);
    // this.signaturePad.set('canvasHeight', this.container.nativeElement.clientHeight);
	}

	drawComplete() {
		this.isSigned = true;
	}

	drawStart() {
	}

	clear() {
		this.signaturePad.clear();
		this.isSigned = false;
	}

	setSignature() {
		this.signed.emit((this.isSigned === true) ? this.signaturePad.toDataURL() : null);
	}

}
