// @i18n

export const EVENTCODE_TLD = '@kncandidateday.fr';
export const COUNTRY_NAME = 'France';
export const COUNTRY_SHORTCUT = 'FR';

export const MAX_EVENT_LENGTH = 14;

export const DATETIMES: any = {
  DATE_SHORT: 'DD MMM',
  DATE_LONG: 'DD MMM YYYY',
  DATE_MONTH_YEAR: 'MMM YYYY',
  DATE_REVERSE: 'YYYY-MM-DD',
  TIME_SHORT: 'HH:mm',
  TIME_LONG: 'HH:mm:ss',
  DATETIME_SHORT: 'DD MMM, HH:mm',
  DATETIME_LONG: 'DD MMM YYYY, HH:mm',
  DATETIME_FULL_REVERSE: 'YYYY-MM-DD HH:mm:ss',
  DATE_PRIMEPICKER_LONG: 'dd M yy',
  DATE_PRIMEPICKER_MONTH_YEAR: 'y',
  HOUR_FORMAT: 24,
  FIRST_DAY_OF_WEEK: 1
}

export const USER_ROLES: any = {
	administrator: 'Administrator',
	genius: 'Genius',
	recruiter: 'Recruteur',
	'registration-clerk': 'Responsable de l\'enregistrement',
	'back-office': 'Back-Office',
	management: 'Direction',
};

export const META_ROLES: any = {
	user: 'Utilisateur',
	admin: 'Administrateur',
	superuser: 'SuperUser'
};

export const CURRENCIES_SHORT: any = {
	USD: '$',
	GBP: '£',
	EUR: '€'
}

export const EVENT_TYPES: any = {
	// 'candidate-day': 'La Journée du CANDIDAT KN',
	'candidate-friday': 'Le Moment du CANDIDAT KN',
};

export const EVENT_STATUSES: any = {
	setup: 'Configuration',
	standby: 'Mis en veille',
	online: 'Publié',
	running: 'En cours',
	finished: 'Achevé',
	archived: 'Archivé',
};

export const EVENTDAY_STATUSES: any = {
	setup: 'Configuration',
	standby: 'Mis en veille',
	running: 'En cours',
	finished: 'Achevé',
	archived: 'Archivé',
};

export const CANDIDATEEVENT_STATUSES: any = {
	registered: 'Profil incomplet',
	noshow: 'Ne s’est pas présenté',
	interview: 'Entretien planifié',
	finished: 'Achevé',
};

export const CANDIDATEEVENT_INTERVIEW_STATUSES: any = {
	hired: 'Embauché',
	pooled: 'Placé en vivier',
	rejected: 'Non-Retenu',
	declined: 'Offre Déclinée',
};

export const CANDIDATEEVENT_LISTSTATUSES: any = {
	registered: 'Profil incomplet',
	ready: 'Profil complet',
  scheduled: 'Entretien prévu',
	noshow: 'Ne s’est pas présenté',
	interview: 'Entretien planifié',
	finished: 'Achevé',
};

export const REASONS: any = {
	pooled: [
    'La date disponibilité du candidat ne correspond pas au besoin, à conserver en vivier',
    'Profil ne correspond pas 100% au besoin, à conserver en vivier',
    'Le poste recherché  par le candidat n\'est pas disponible',
    'Les prétentions salariales du candidat sont trop élevées',
	],
	rejected: [
    'Profil ne correspond pas au besoin',
    'Le candidat ne peut pas accéder au site aux horaires convenus (moyens de transport)',
    'Expérience professionnelle insuffisante',
    'Expérience professionnelle en décalage avec le besoin',
    'Prétentions salariales trop élevées',
	],
	declined: [
		'Je ne souhaite pas répondre',
    'Raisons personnelles',
    'Proposition salariale trop faible',
    'Avantages ne sont pas suffisamment attrayants',
    'Horaires associés au  poste ne conviennent pas',
    'Préférence pour employeur actuel',
    'Distance pour se rendre sur le lieu de travail trop élevée',
    'Lieu de travail ne convient pas',
	],
};

export const WORKFORCE: any = {
	'Blue collar': 'Col Bleu',
	'White collar': 'Col Blanc',
};

export const WORKFORCE_LEVEL: any = {
	1: 1,
	2: 2,
	3: 3,
};

export const ACTION_REASON: any = {
	'New Hire (NHR)': 'New Hire (NHR)',
	'Rehire (REH)': 'Rehire (REH)',
	'Transfer from Affiliate (HAF)': 'Transfer from Affiliate (HAF)',
};

export const JOBTYPE_STATUS: any = {
	employee: 'Ouvrier Sédentaire',
	// 'middle management': 'middle management',
	// executive: 'executive'
};

export const REQ_EMPLOYMENT_TYPE: any = {
	Permanent: 'Permanent',
	Temporary: 'Temporary',
};

export const REQ_FULLPART_TIME: any = {
	'Full Time': 'Full Time',
	'Part Time': 'Part Time',
};
