import { Injectable } from '@angular/core';

export const MAXYEARS = 60;
export const FILTER_PATTERN_IDNUMBER = '^[0-9]+$';
export const FILTER_PATTERN_NAME = '^[A-Za-z\u00C0-\u00FF\- ]*$';
export const VALIDATOR_PATTERN_IDNUMBER = '^[0-9]+$';
export const VALIDATOR_PATTERN_NAME = '.*[A-Za-z]+.*'; // '^[A-Za-z\u00C0-\u00FF\-\' ]*$';
export const VALIDATOR_PATTERN_ADDRESS = '[A-Za-z0-9\u00C0-\u00FF\(\)\-\/\' ]*';
export const VALIDATOR_PATTERN_ZIP = '^.*$';
export const VALIDATOR_PATTERN_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALIDATOR_PATTERN_MOBILE = '^\\+[0-9]+[0-9\(\)\-/ ]*$';
export const VALIDATOR_PATTERN_MIN_ONE_LETTER = '.*[A-Za-z]+.*';

@Injectable({
  providedIn: 'root'
})
export class SbLibService {

  constructor() { }
}
