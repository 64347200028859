<ng-container *transloco="let t;">
<div [hidden]="registrationSuccess === true || registrationLoading === true">

	<app-personal-data [hidden]="data.step !== 1" [event]="event" [mode]="mode" [init]="init" [(data)]="data" [errors]="errors"></app-personal-data>
	<app-work-history [hidden]="data.step !== 2" [event]="event" [(data)]="data"></app-work-history>
	<app-job-skills [hidden]="data.step !== 4" [event]="event" [(data)]="data" (registerClicked)="register()"></app-job-skills>
	<app-slot [hidden]="data.step !== 5" [event]="event" [(data)]="data" (registerClicked)="register()"></app-slot>
</div>
<div *ngIf="slotError === true">
	<app-slotselector [event]="event" (set)="setSlot($event)"></app-slotselector>
</div>
<div *ngIf="registrationLoading === true">
	<div class="uk-modal-body">
		<div class="uk-section">
			<p class="uk-text-center"><span uk-spinner="ratio: 3"></span></p>
			<p class="uk-text-center">
				{{ t('partials.preregistration.prereg_friday.sending_registration') }}
			</p>
		</div>
	</div>
</div>
<div *ngIf="registrationSuccess === true">
	<div class="uk-modal-body">
		<div class="uk-section form-success">
			<p class="uk-text-center"><span uk-icon="icon: check; ratio: 3"></span></p>
			<p class="uk-text-center">
				<strong>{{ t('partials.preregistration.prereg_friday.thank_you_for_registration') }}</strong>
			</p>
			<p class="uk-text-center">
				{{ t('partials.preregistration.prereg_friday.should_receive_confirmation') }}
			</p>
		</div>
	</div>
	<div class="uk-modal-footer uk-text-right">
		<button class="uk-button kn-button uk-modal-close">{{ t('common.close') }}</button>
	</div>
</div>

</ng-container>
