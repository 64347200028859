import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SignaturePadModule } from 'angular2-signaturepad';

import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { SbFormsModule } from '../forms/forms.module';

import { ApploaderComponent } from './components/apploader/apploader.component';
import { CircularComponent } from './components/circular/circular.component';
import { LoadingComponent } from './components/loading/loading.component';
import { EmptyhintComponent } from './components/emptyhint/emptyhint.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { DatalistComponent } from './components/datalist/datalist.component';
import { ButtonComponent } from './components/button/button.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { CardComponent } from './components/card/card.component';
import { AppcontainerComponent } from './components/appcontainer/appcontainer.component';
import { SignaturefieldComponent } from './components/signaturefield/signaturefield.component';
import { OfferletterComponent } from './components/offerletter/offerletter.component';
import { OfferletterDeclineComponent } from './components/offerletter-decline/offerletter-decline.component';
import { ListselectorComponent } from './components/listselector/listselector.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
	declarations: [
		ApploaderComponent,
		CircularComponent,
		LoadingComponent,
		EmptyhintComponent,
		AvatarComponent,
		DatalistComponent,
		ButtonComponent,
		ImageCropperComponent,
		CardComponent,
		AppcontainerComponent,
		SignaturefieldComponent,
		OfferletterComponent,
		OfferletterDeclineComponent,
		ListselectorComponent
	],
	imports: [
		CommonModule,
		RoundProgressModule,
		ImageCropperModule,
		NgxFileDropModule,
		SbFormsModule,
		SignaturePadModule,
    ConfirmDialogModule,
    TranslocoModule,
    FontAwesomeModule
	],
	exports: [
		ApploaderComponent,
		CircularComponent,
		LoadingComponent,
		EmptyhintComponent,
		AvatarComponent,
		DatalistComponent,
		ButtonComponent,
		ImageCropperComponent,
		CardComponent,
		AppcontainerComponent,
		SignaturefieldComponent,
		OfferletterComponent,
		OfferletterDeclineComponent,
		ListselectorComponent
	]
})
export class SbUiModule {}
