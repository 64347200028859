import { PreregModes } from './../partials/preregistration/preregistration.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from 'projects/environments/environment';

import { LoginService } from './login.service';
import { Searchvalues } from '../types/searchvalues';

import { CandidateEventModel } from 'projects/sb-lib/src/lib/models/candidateevent.model';
import { CandidateModel } from 'projects/sb-lib/src/lib/models/candidate.model';
import { EventModel } from 'projects/sb-lib/src/lib/models/event.model';
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(
		private http: HttpClient,
		private loginService: LoginService,
    private app: AppService
	) { }

	getSystemSetup(): Observable<any> {
		return this.http
			.get<any>(this.getUrl('setup'))
			.pipe(
				catchError(err => {
					return throwError(this.getError(err));
				})
			);
	}

  getEnums(): Observable<any> {
		return this.http.get<any>(this.getUrl('setup/enums')).pipe(
			map((result: any) => {
        return result.data;
			}),
			catchError(err => {
				return throwError(this.getError(err));
			})
		);
  }

	getEvents(mode: string, eventid: string | null = null, marketingsource: string | null = null): Observable<EventModel[]> {
    let url = this.getUrl('events') + '?mode=' + mode + '&hash=' + this.app.getVisitorHash();
    if (marketingsource !== null) {
      url += '&marketingsource=' + marketingsource;
    }
    if (eventid !== null) {
      url += '&eventid=' + eventid;
    }
		return this.http.get<EventModel[]>(url)
			.pipe(
				map((result: any) => {
					const events: EventModel[] = [];
					result.data.forEach((event: any) => {
						events.push(new EventModel().deserialize(event));
					});
					return events;
				}),
				catchError(error => throwError(error.status))
			);
	}

  getContent(page: string): Observable<string> {
		return this.http.get<any>(this.getUrl('content/' + page)).pipe(
			map((result: any) => {
        return result.data;
			}),
			catchError(err => {
				return throwError(this.getError(err));
			})
		);
  }

	login(payload: any): Observable<any> {
		return this.http.post<any>(this.getUrl('login'), payload)
			.pipe(
				map(result => {
					this.loginService.setLoggedIn(result.token, result.candidate);
					return {
						success: true,
						noshow: (result.candidate.was_noshow === 1)
					};
				}),
				catchError(error => throwError({
					error: error,
					success: false,
					noshow: null
				}))
			);
	}

	loginWithMailToken(payload: any): Observable<any> {
		return this.http.post<any>(this.getUrl('pass'), payload)
			.pipe(
				map(result => {
					this.loginService.setLoggedIn(result.token, result.candidate);
					return {
						success: true,
						noshow: (result.candidate.was_noshow === 1)
					};
				}),
				catchError(error => throwError({
					error: error,
					success: false,
					noshow: null
				}))
			);
	}

	register(payload: any, mode: string = PreregModes.REGISTRATION): Observable<any> {
    const url = (mode === PreregModes.REGISTRATION) ? 'register' : 'candidate/collect-data-day';
		return this.http.post<any>(this.getUrl(url), payload)
			.pipe(
				catchError(err => {
					return throwError(this.getError(err));
				})
			);
	}

	registerFriday(payload: any, mode: string = PreregModes.REGISTRATION): Observable<any> {
    const url = (mode === PreregModes.REGISTRATION) ? 'register-friday' : 'candidate/collect-data-friday';
		return this.http.post<any>(this.getUrl(url), payload)
			.pipe(
				catchError(err => {
					return throwError(this.getError(err));
				})
			);
	}

	resetPassword(payload: any): Observable<any> {
		return this.http.post<any>(this.getUrl('reset-password'), payload)
			.pipe(
				catchError(err => {
					return throwError(this.getError(err));
				})
			);
	}

	getJobdata(event_id: string): Observable<any> {
		return this.http.get<any>(this.getUrl('data/jobdata/' + event_id))
			.pipe(
        map((result: any) => {
          return result.data;
        }),
				catchError(error => throwError(error.status))
			);
	}

	getFreeslots(event_id: string): Observable<any> {
		return this.http.get<any>(this.getUrl('data/freeslots/' + event_id))
			.pipe(
        map((result: any) => {
          return result.data;
        }),
				catchError(error => throwError(error.status))
			);
	}

	getSearchvalues(): Observable<Searchvalues> {
		return this.http.get<Searchvalues>(this.getUrl('data/searchvalues'))
			.pipe(
        map((result: any) => {
          return result.data;
        }),
				catchError(error => throwError(error.status))
			);
	}

	searchEvents(payload: any): Observable<EventModel[]> {
		return this.http.post<EventModel[]>(this.getUrl('eventsearch'), payload)
			.pipe(
				map((result: any) => {
					const events: EventModel[] = [];
					result.data.forEach((event: any) => {
						events.push(new EventModel().deserialize(event));
					});
					return events;
				}),
				catchError(error => throwError(error.status))
			);
	}

	// profile functions

	getProfile(): Observable<any> {
		return this.http.get<any>(this.getUrl('candidate/profile'))
			.pipe(
				catchError(error => throwError(error.status))
			);
	}

	postProfile(candidate: CandidateModel): Observable<CandidateModel> {
		return this.http.post<CandidateModel>(this.getUrl('candidate/profile'), {
			candidate: candidate
		})
			.pipe(
				map(candidate => new CandidateModel().deserialize(candidate)),
				catchError(err => {
					return throwError(this.getError(err));
				})
			);
	}

	getCandidateEvents(): Observable<CandidateEventModel[]> {
		return this.http.get<CandidateEventModel[]>(this.getUrl('candidate/events'))
			.pipe(
				map((result: any) => {
					const list: CandidateEventModel[] = [];
					result.data.forEach((candidateevent: any) => {
						list.push(new CandidateEventModel().deserialize(candidateevent));
					});
					return list;
				}),
				catchError(error => throwError(error.status))
			);
	}

	linkCandidateEvent(event_id: string): Observable<any> {
		return this.http.post<any>(this.getUrl('candidate/event'), {
			event_id: event_id
		})
			.pipe(
				catchError(error => throwError(error.status))
			);
	}

	deleteCandidateEvent(event_id: string): Observable<any> {
		return this.http.delete<any>(this.getUrl('candidate/events/' + event_id))
			.pipe(
				catchError(error => throwError(error.status))
			);
	}

	bookSlot(payload: any): Observable<CandidateEventModel> {
		return this.http.post<CandidateEventModel>(this.getUrl('candidate/slot'), payload)
			.pipe(
				map(candidateevent => new CandidateEventModel().deserialize(candidateevent)),
				catchError(error => throwError(error.status))
			);
	}

	deleteSlot(event_id: string): Observable<CandidateEventModel> {
		return this.http.delete<CandidateEventModel>(this.getUrl('candidate/slot/' + event_id))
			.pipe(
				map(candidateevent => new CandidateEventModel().deserialize(candidateevent)),
				catchError(error => throwError(error.status))
			);
	}

	setJob(payload: any): Observable<CandidateEventModel> {
		return this.http.post<CandidateEventModel>(this.getUrl('candidate/job'), payload)
			.pipe(
				map(candidateevent => new CandidateEventModel().deserialize(candidateevent)),
				catchError(error => throwError(error.status))
			);
	}

	setSkills(payload: any): Observable<CandidateEventModel> {
		return this.http.post<CandidateEventModel>(this.getUrl('candidate/skills'), payload)
			.pipe(
				map(candidateevent => new CandidateEventModel().deserialize(candidateevent)),
				catchError(error => throwError(error.status))
			);
	}


	setSearchRadius(radius: number): Observable<any> {
		return this.http.post<any>(this.getUrl('candidate/searchradius'), {radius: radius})
			.pipe(
				catchError(error => throwError(error.status))
			);
	}

  deleteProfile(): Observable<any> {
		return this.http.delete<any>(this.getUrl('candidate/profile'))
			.pipe(
				catchError(err => {
					return throwError(this.getError(err));
				})
			);
  }

  // offerletter

	getOfferletter(id: string): Observable<CandidateEventModel> {
		return this.http.get<any>(this.getUrl('offerletter/' + id))
			.pipe(
        map((result: any) => {
          return new CandidateEventModel().deserialize(result.data)
        }),
				catchError(error => throwError(error.status))
			);
	}

	downloadOfferletter(id: string): Observable<any> {
		return this.http.get<any>(this.getUrl('offerletter/' + id + '/download'))
			.pipe(
				catchError(error => throwError(error.status))
			);
	}

	acceptOfferletter(id: string, signature: string): Observable<any> {
		return this.http.post<any>(this.getUrl('offerletter/' + id + '/accept'), {
			signature
		}).pipe(
			catchError(error => throwError(error.status))
		);
	}

	declineOfferletter(id: string, reasons: Array<string>): Observable<any> {
		return this.http.post<any>(this.getUrl('offerletter/' + id + '/decline'), { reasons }).pipe(
			catchError(error => throwError(error.status))
		);
	}

	getAvatarImageUploadUrl(): string {
		return this.getUrl('candidate/avatar/upload');
	}

	private getUrl(route: string): string {
		return (environment as any).serverUrl + '/api/microsite/' + route;
	}

	private getError(err: any): any {
		const fields: any = {};
		let common = [];
		const errors = err.error;
		for (const key in errors) {
			if (!errors.hasOwnProperty(key)) { continue; }
			if (key === 'common') {
				common = errors[key];
			} else {
				fields[key] = errors[key];
			}
		}
		return {
			status: err.status,
			common,
			fields
		};
	}

}
